<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <!-- Header -->
          <div class="d-flex align-items-center line-height-1 pb-2">
            <i class="text-primary ti ti-school small-gap-right" />
            <h4 class="text-primary mb-0">
              Daftar Request Pelatihan
            </h4>
          </div>

          <!-- Table of user-->
          <div>
            <!-- table -->
            <vue-good-table
              mode="remote"
              :is-loading.sync="isLoading"
              :total-rows="totalRecords"
              :columns="columns"
              :rows="rows"
              :sort-options="{
                enabled: true,
                initialSortBy: { field: 'name', type: 'asc' },
              }"
              :select-options="{
                enabled: false,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              :pagination-options="{
                enabled: true,
                nextLabel: 'selanjutnya',
                prevLabel: 'sebelumnya',
                rowsPerPageLabel: 'Data per halaman',
                ofLabel: 'dari',
                pageLabel: 'halaman', // for 'pages' mode
                allLabel: 'Semua',
              }"
              :line-numbers="true"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Name -->
                <span
                  v-if="props.column.field === 'user_name'"
                  class="text-nowrap"
                >
                  <b-avatar
                    :src="props.row.user_avatar"
                    :text="avatarText(props.row.user_name)"
                    variant="light-primary"
                    class="mx-1"
                  />
                  <span class="text-nowrap">{{ props.row.user_name }}</span>
                </span>

                <!-- Column: Status -->
                <span
                  v-else-if="props.column.field === 'status'"
                  class="d-flex justify-content-start"
                >
                  <b-badge
                    :variant="props.row.status | statusClass"
                    class="small-gap-right"
                  >
                    {{ props.row.status }}
                  </b-badge>
                </span>

                <!-- Column: Action -->
                <span
                  v-else-if="props.column.field === 'action'"
                  class="d-flex justify-content-start"
                >
                  <b-button
                    variant="warning"
                    size="sm"
                    class="mr-1"
                    :to="{
                      name: 'staff-training-requests.show',
                      params: {
                        trainingRequestId: props.row.id
                      }
                    }"
                  >
                    <i class="ti ti-pencil" />
                  </b-button>
                  <b-button
                    variant="danger"
                    size="sm"
                    class="mr-1"
                    @click="handleDelete(props.row)"
                  >
                    <i class="ti ti-trash" />
                  </b-button>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'

import {
  BRow,
  BCol,
  BCard,
  BButton,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormRadio,
  BFormCheckbox,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { pascalCase, avatarText } from '@core/utils/filter'
import { checkAuthorizeRole } from '@/auth/utils'
import { reqGetTrainingRequests, reqDeleteTrainingRequest } from '@/api/admin/trainingRequest'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormRadio,
    BFormCheckbox,

    VueGoodTable,
    vSelect,
  },
  filters: {
    statusClass(status) {
      if (status == 'Menunggu Persetujuan') {
        return 'secondary'
      } if (status == 'Diterima') {
        return 'success'
      }
      return 'danger'
    },
  },
  props: {},
  data() {
    return {
      // Filter
      isLoading: true,
      statuses: ['Menunggu Persetujuan', 'Diterima', 'Ditolak'],

      // Table option
      rows: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {
          field: '',
          type: '',
        },

        page: 1,
        per_page: 10,
      },
      columns: [
        {
          label: 'Nama Pelatihan',
          field: 'name',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Cari nama pelatihan', // placeholder for filter input
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, // custom filter function that
            trigger: 'change', // only trigger on enter not on keyup
          },
        },
        {
          label: 'Nama User',
          field: 'user_name',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Cari nama user', // placeholder for filter input
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, // custom filter function that
            trigger: 'change', // only trigger on enter not on keyup
          },
        },
        {
          label: 'Status',
          field: 'status',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Semua Status', // placeholder for filter input
            filterDropdownItems: ['Menunggu Persetujuan', 'Diterima', 'Ditolak'], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, // custom filter function that
            trigger: 'change', // only trigger on enter not on keyup
          },
        },

        {
          label: 'Aksi',
          field: 'action',
          width: '5%',
          sortable: false,
        },
      ],
    }
  },
  computed: {
  },
  created() {
    this.loadItems()
  },
  methods: {
    loadItems() {
      reqGetTrainingRequests(this.serverParams).then(response => {
        this.totalRecords = response.data.data.total
        this.rows = response.data.data.data.map(item=>{
          return {
            id: item.id,
            name: item.name,
            purpose: item.purpose,
            status: item.status,
            user_name: item.user.name,
            user_avatar: item.user.avatar
          }
        })
        this.isLoading = false
      })
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },

    handleEdit(row) {
      this.$router.push({
        name: 'users.edit',
        params: {
          id: row.id,
        },
      })
    },
    handleDelete(row) {
      this.$swal({
        title: 'Apakah Anda Yakin?',
        text: 'Anda tidak akan bisa mengembalikan data ini lagi!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal',
      }).then(result => {
        if (result.isConfirmed) {
          reqDeleteTrainingRequest(row.id)
            .then(response => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Berhasil',
                  icon: 'Check',
                  message: response.data.message || 'Berhasil menghapus data',
                  variant: 'success',
                },
              })
              this.loadItems()
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Gagal',
                  icon: 'Check',
                  message:
                    error.response.data.message || 'Gagal menghapus data',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
    triggerModal() {
      this.$bvModal.show('content-add-modal')
    },
    avatarText,
    pascalCase,
    checkAuthorizeRole,
  },
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-good-table.scss";
.small-gap-right {
  margin-right: 8px;
}

.small-gap-bottom {
  margin-bottom: 8px;
}

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.is-invalid {
  border-color: #ea5455 !important;
}

.line-numbers {
  padding: 0 1.2em !important;
}

.school-filter {
  min-width: 180px !important;
}
</style>
